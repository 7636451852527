.axis-net-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 258px;
    height: 100%;
    flex-shrink: 0;
    background-color: @fill-1;

    .side-title {
        color: @fill-black;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 32px;
        padding: 10px 0 6px 0;
        margin: 0 8px 10px 8px;
        border-bottom: 1px solid @border-3;
    }

    .head {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 2px;
        background: @primary-1;
        height: 21px;
        margin: 0 8px 6px 8px;
        padding: 2.5px 4px 2.5px 13px;
        flex-shrink: 0;

        color: @text-6;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .tn-icon-button {
            margin-left: auto;
        }
    }

    .list {
        padding: 0 8px;
        flex: 1 1 auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 1px;

        .list-content {
            flex: 1 1 auto;
            overflow: auto;

            .ant-table-wrapper {
                overflow: auto;
            }
        }
    }
}

.ant-table-wrapper.tn-storey-table {
    overflow: auto;
    background-color: transparent;

    .ant-table {
        background: transparent;
        border: 0;

        .ant-table-row {
            background-color: transparent;
        }

        .ant-table-header {
            border-radius: 0;

            .ant-table-cell {
                border-radius: 0;
                border-bottom: 0;
            }
        }

        .ant-table-cell .ant-table-cell-row-hover {
            background-color: transparent;
            padding: 0 4px;
        }
    }

    .ant-table-thead {
        padding: 0 !important;
        margin: 0 !important;
        // border-bottom: 1px solid yellow;

        tr {
            &:first-child {
                border-top-left-radius: 0 !important;
            }
        }

    }

    .ant-table-thead>tr>th {
        padding: 0;
        background-color: transparent;
        color: #fff;
        font-size: 12px;
        border-radius: 0 !important;

        &::before {
            content: none !important;
        }

        >.head-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0 4px;
            width: 100%;
            background-color: #FFF;
            height: 21px;
            border-radius: 0;

            color: @text-4;
            font-size: 12px;
            font-weight: 400;
            line-height: 21px;
        }

        >.left-head-cell {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0 4px;
            width: 100%;
            background-color: #FFF;
            height: 21px;
            border-radius: 0 !important;

            color: @text-4;
            font-size: 12px;
            font-weight: 400;
            line-height: 21px;
        }

    }

    .ant-table-tbody {

        >tr>td {
            padding: 0 !important;
            color: @text-2;
            font-size: 12px;

            &:hover {
                // color: #FFF;
                background-color: @fill-2;
            }
        }

        tr.ant-table-row:hover>td,
        td.ant-table-cell.ant-table-cell-row-hover {
            background: transparent;
            // background: @fill-2;
        }

        tr.ant-table-row:hover>td>.editable-cell-value-wrap {
            color: @text-2;
            font-size: 12px;
            font-weight: 400;
        }

        .icon-area {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            // &:hover {
            //     background: @fill-2;
            // }
        }

        .ant-table-placeholder {
            background: transparent;

            &:hover>td {
                background: transparent;
            }

            .ant-empty-description {
                color: white;
            }
        }

        .editable-cell-value-wrap {
            // background: rgba(0, 0, 0, 0.40);
            background: transparent;
            color: @text-2;
            padding: 0 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 24px;
            line-height: 24px;

            &:hover {
                background-color: @fill-2;
            }

            // border-left: 1px solid red;
            // border-right: 1px solid red;
        }

        .editable-cell-value-input {
            height: 24px;
            line-height: 1;
            // background: rgba(0, 0, 0, 0.40);
            background: transparent;
            padding: 0 4px;

            .ant-form-item-control-input {
                min-height: 24px;
            }

            input {
                background: #fff;
                height: 24px;
                border-radius: 0;
                font-size: 12px;
            }
        }
    }

    .ant-table-tbody>tr>td {
        padding: 1px;
        border-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ant-table-body::-webkit-scrollbar {
        width: 0 !important;
    }

    .ant-table-body {
        -ms-overflow-style: none;
        border: 0;
    }

    .ant-table-header::-webkit-scrollbarborder {
        // 右侧有滚动条时，表头会有个小块
        display: none;
    }

    .read-only-cell {
        width: 100%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgba(0, 0, 0, 0.4);
        height: 24px;
        line-height: 24px;
        // display: flex;
        // align-items: center;
        padding: 0 4px;

        &-right {
            text-align: right;
        }
    }

    // .ant-table-summary {
    //     background: transparent;
    //     border: 0;

    //     tr {
    //         border: 0;
    //     }

    //     .ant-table-cell {
    //         border: 0;
    //         padding: 1px;
    //         // background: rgba(0, 0, 0, 0.80);
    //         color: white;

    //         .summary-cell {
    //             // background: rgba(0, 0, 0, 0.80);
    //             color: white;
    //         }
    //     }

    //     >td>.summary-text {
    //         width: 100% !important;
    //         height: 24px;
    //         line-height: 24px !important;
    //         padding: 0 4px;
    //         background-color: rgba(0, 0, 0, 0.40);
    //         color: #FFF;
    //         text-align: right;
    //         font-size: 12px;
    //     }
    // }

    // .ant-table-summary>td>.ant-table-cell {
    //     margin: 1px;
    // }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;