.capture-setting-container {
    padding: 5px 10px;
    background-color: #F0F0F0;
    height: 270px;

    .ant-tabs {
        height: 230px;
    }

    .ant-tabs-card.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
        padding: 0 3px;
    }

    .ant-tabs-top>.ant-tabs-nav,
    .ant-tabs-bottom>.ant-tabs-nav,
    .ant-tabs-top>div>.ant-tabs-nav,
    .ant-tabs-bottom>div>.ant-tabs-nav {
        margin: 0;
    }

    .ant-tabs-content-holder {
        padding: 10px;
        background-color: white;
    }

    .manage-header {
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .manage-check {
        // margin: 20px;
        display: flex;
        justify-content: space-between;
    }

    .angle-select {
        display: flex;
        margin-top: 5px;
        margin-left: 10px;
        height: 25px;
        width: 180px;
        padding-bottom: 0;
        align-items: center;

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            box-shadow: none;
        }
    }

    .capture-item-group {
        // margin-left: 45px;
        // height: 250px;
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0;
        justify-content: center;

        .capture-item {
            display: flex;
            align-items: center;
            width: 30%;
        }
    }

    .manage-foot {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
    }

}


.menu-container {
    background-color: white;
    border: 1px solid #7F7F7F;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;

    .menu-item {
        display: flex;
        align-items: center;
        // width: 134px;
        cursor: pointer;
        user-select: none;
        color: black;

        &:hover {
            background-color: #0078D7;
            color: white;
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;