.side-bar-main {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: @fill-1;


    .body {
        padding: 10px 8px;
        display: flex;
        flex-direction: column;

        display: flex;
        flex: 1 1 auto;
        box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.08);

        overflow: hidden;


        .title {
            color: @fill-black;
            font-family: "Microsoft YaHei";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 132.497%;

            display: flex;
            width: 242px;
            padding: 0px 8px 6px 0px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;

            border-bottom: 1px solid @border-3;

            height: 22px;
        }

        .detail {
            margin-top: 10px;
            padding: 0px 12px;
            height: 100%;
            
            overflow: hidden;

            .row {
                display: flex;
                align-items: center;
                height: 24px;
                margin-bottom: 6px;
            }

            .title-row {
                margin-inline: -12px;
                display: flex;
                width: 242px;
                padding: 2px 12px;
                align-items: flex-start;
                border-radius: 2px;
                background: @primary-1;
                margin-bottom: 4px;

                &-text {
                    color: @text-6;
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .label {
                width: 76px;
                flex-shrink: 0;
                color: @text-1;
                font-family: "Microsoft YaHei";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 132.497%;
            }

            .select-box {
                flex: 1 1 auto;
                align-items: center;
                width: 100px;
                display: flex;
                flex-shrink: 0;
                overflow: hidden;
            }

            .input-box {
                display: flex;
                height: 24px;
                padding: 1px 12px 1px 6px;
                justify-content: space-between;
                align-items: center;
                flex: 1 0 0;
                box-shadow: none !important;

                border-radius: 2px;
                border: 1px solid @border-2;
                background: @primary-2;

                color: @text-3;
                font-family: "Hiragino Sans GB";
                font-size: 12px;
                font-style: normal;
                // font-weight: 300;
                line-height: 20px;

                &.onlyread {
                    border: none !important;
                    background-color: transparent;
                }

                &:hover {
                    border: 1px solid @border-3;
                    color: @text-4;
                }
            }

            .ant-input-affix-wrapper {
                &-focused {
                    color: @text-6;
                    border: 1px solid @primary-6 !important;
                    box-shadow: none;
                }

                &:focus-within {
                    box-shadow: none;
                }

                &:focus {
                    box-shadow: none;
                }

                .ant-input-suffix {
                    color: @text-5;
                    font-family: "Hiragino Sans GB";
                    font-size: 12px;
                    font-style: normal;
                    // font-weight: 300;
                    line-height: 20px;
                }
            }

            .upload-button {
                cursor: pointer;
                margin-left: 16px;
                width: 22px;
                height: 20px;
                flex-shrink: 0;
                border-radius: 2px;
                border: 1px solid @border-1;
                background: @fill-1;

                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background: @fill-2;
                }

                &:active {
                    background: @fill-4;
                }
            }

            .ope-btn {
                display: flex;
                justify-content: flex-end;
                height: 28px;
                align-items: center;
            }

            .insert-button {
                cursor: pointer;
                width: 70px;
                height: 24px;
                display: flex;
                padding: 2px 8px 2px 6px;
                justify-content: center;
                align-items: center;
                gap: 4px;

                border-radius: 2px;
                border: 1px solid @border-6;
                background: @fill-white;

                &:hover {
                    border: 1px solid @primary-5;

                    svg[class="icon-svg"] {
                        fill: @primary-5;
                    }

                    .text {
                        color: @primary-5;
                    }
                }

                &:active {
                    border: 1px solid @primary-6;

                    svg[class="icon-svg"] {
                        fill: @primary-6;
                    }

                    .text {
                        color: @primary-6;
                    }
                }

                .icon {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .text {
                    color: @fill-black;
                    font-family: "Microsoft YaHei UI";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    width: 28px;
                    flex-shrink: 0;
                }
            }

            .ope-area {
                display: flex;
                height: 32px;
                padding: 4px 0px;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 4px;

                .primary-btn {
                    cursor: pointer;
                    display: inline-flex;
                    padding: 4px 6px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    height: 24px;

                    border-radius: 2px;
                    background: @primary-6;

                    color: @fill-white;
                    font-family: "Microsoft YaHei UI";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    // line-height: 16px;

                    &:hover {
                        background: @primary-5;
                    }

                    &:active {
                        background: @primary-4;
                    }
                }

                .secondary-btn {
                    cursor: pointer;
                    display: inline-flex;
                    padding: 4px 6px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    height: 24px;

                    border-radius: 2px;
                    border: 1px solid @border-6;
                    background: @fill-white;

                    &:hover {
                        border: 1px solid @primary-5;

                        .text {
                            color: @primary-5;
                        }
                    }

                    &:active {
                        border: 1px solid @primary-6;

                        .text {
                            color: @primary-6;
                        }
                    }

                    .text {
                        color: @fill-black;
                        font-family: "Microsoft YaHei UI";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        // line-height: 16px;
                    }

                }
            }
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;