.tn-menu-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .ope-panel {
        display: flex;
        flex-direction: row;
        margin-right: 13px;

        .ope-col-area {
            display: flex;
            flex-direction: column;
            height: 106px;

            .ope-btn {
                display: flex;
                flex-direction: row;
                padding: 4px 8px;
                height: 100%;
                background: rgba(219, 219, 219, 0.20);
                border-right: 1px solid #BDBDBD;

                .ope-col {
                    display: flex;
                    flex-direction: column;

                    .ope-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 76px;
                        // height: 24px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        flex-shrink: 0;
                        padding-left: 4px;
                        color: #000;
                        cursor: pointer;

                        .icon {
                            display: flex;
                            margin-right: 4px;
                        }

                        .name {
                            flex: 1 1 auto;
                            color: #000 !important;
                            font-family: Hiragino Sans GB;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                        }

                        .arrow {
                            width: 14px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:hover {
                                background-color: #a7bcc2;
                                // background-color: @hover-color;
                            }
                        }

                        &:hover {
                            display: flex;
                            background-color: @primary-2;
                        }

                        &:active {
                            background-color: @primary-4;
                        }

                        .ant-badge {
                            font-size: 12px;
                        }
                    }
                }
            }

            .ope-name {
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                text-align: center;
                font-family: "Microsoft YaHei";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                background: rgba(219, 219, 219, 0.20);
                border-right: 1px solid #BDBDBD;
                padding-bottom: 10px;
            }
        }
    }



    .right-arrow {
        display: flex;
        margin-right: 5px;
        margin-left: 2px;
        z-index: 2;
        cursor: pointer;
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;