.system-switch {

    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    padding-right: 4px;

    // gap: 1px;

    .coord {
        height: 100%;
        width: 357px;
        padding-inline: 6px;
        flex-shrink: 0;
        background: #FFF;

        display: flex;
        align-items: center;
        justify-content: right;

        white-space: nowrap;
    }

    .bottom-work-plane {
        height: 100%;
        // width: 280px;
        padding-inline: 6px;
        flex-shrink: 0;
        background: #FFF;

        display: flex;
        align-items: center;
        justify-content: right;
        white-space: nowrap;

        .name {
            display: flex;
            align-items: center;
            justify-content: right;
            gap: 8px;
            // margin-left: 16px;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }

    .divider {
        width: 0;
        height: 100%;
        border-right: 1px solid #0088CF;
        margin: 0 8px;
    }

    .switch-group {
        display: flex;
        height: 100%;
        align-items: center;
        background-color: #FFF;
        // border: 1px solid #0088CF;
        padding: 0 2px;
        // margin-right: 1px;

        .system-ope {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 22px;
            line-height: 22px;
            font-size: 16px;
            cursor: pointer;
            margin: 0 4px;
            white-space: nowrap;

        }

        .system-triangle {
            padding-top: 9px;
            padding-left: 0px;
            height: 22px;
            width: 14px;
        }

        .system-ope.active {
            color: #0168B3;
        }

        .display-frame {
            display: flex;
            min-width: 280px;
            justify-content: flex-end;
            // text-align: right;
        }
    }




}

.capture-menu-container {
    background-color: #525252;
    border: 1px solid #7F7F7F;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;

    .capture-menu-item {
        display: flex;
        align-items: center;
        width: 120px;
        cursor: pointer;
        user-select: none;
        color: white;

        &:hover {
            background-color: #6A6A6A;
        }

        .check-icon {
            width: 20px;
            text-align: center;
        }

        &.setting {
            margin: 4px 0;
            padding: 0 2px;
            font-size: 13px;
        }
    }

    .menu-divider {
        border-bottom: 1px solid #d8d8d8;
    }
}

.polar-menu-container {
    background-color: #525252;
    border: 1px solid #7F7F7F;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;

    .polar-menu-item {
        display: flex;
        align-items: center;
        width: 134px;
        cursor: pointer;
        user-select: none;
        color: white;

        &:hover {
            background-color: #6A6A6A;
        }

        .check-icon {
            width: 20px;
            text-align: center;
        }

        .multiply {
            color: #BDBDBD;
            white-space: pre;
        }

        &.setting {
            margin: 4px 0;
            padding: 0 2px;
            font-size: 13px;
        }
    }

    .menu-divider {
        border-bottom: 1px solid #d8d8d8;
    }
}

.render-menu-container {
    background-color: #525252;
    border: 1px solid #7F7F7F;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;

    .render-menu-item {
        display: flex;
        align-items: center;
        width: 100px;
        cursor: pointer;
        user-select: none;
        color: white;

        &:hover {
            background-color: #6A6A6A;
        }

        .check-icon {
            width: 20px;
            text-align: center;
        }

        .multiply {
            color: #BDBDBD;
            white-space: pre;
        }

        &.setting {
            margin: 4px 0;
            padding: 0 2px;
            font-size: 13px;
        }
    }

    .menu-divider {
        border-bottom: 1px solid #d8d8d8;
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;