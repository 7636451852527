.create-component-modal-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .top-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 4px 0 8px;
        background-color: @primary-6;
        height: 32px;

        .text {
            color: #FFF;
            font-family: "Microsoft YaHei UI";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .cancel-icon {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            &:hover {
                background-color: @primary-5;
            }

            &:active {
                background-color: @primary-4;
            }
        }
    }

    .content-panel {
        display: flex;
        padding: 14px 8px 16px 16px;
        flex-direction: column;
        gap: 14px;

        .content-row {
            display: flex;
            flex-direction: row;

            &-left {
                color: @text-1;
                font-family: "Microsoft YaHei";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 36px;
                flex-shrink: 0;
            }

            &-right {

                .ant-input {
                    height: 24px;
                    padding: 1px 6px;
                    border-radius: 2px;
                    border: 1px solid @border-2;
                    background: @primary-2;
                    color: @text-3;
                    line-height: 24px;

                    &:hover {
                        color: @text-4;
                        border: 1px solid @border-3;
                    }

                    &:focus {
                        color: @text-6;
                        border: 1px solid @primary-6;
                    }
                }

                .alignment-btn {
                    color: @text-6;
                    font-family: "Hiragino Sans GB";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    display: flex;
                    height: 24px;
                    padding: 1px 6px;
                    align-items: center;
                    gap: 4px;
                    border-radius: 2px;
                    border: 1px solid @border-2;
                    background: @primary-2;
                    cursor: pointer;
                }

                .ant-select {
                    height: 24px;
                    width: 127px;

                    .ant-select-selector {
                        height: 24px;
                        padding: 2px 6px;
                    }

                    .ant-select-selection-item {
                        overflow: hidden;
                        color: @text-6;
                        text-overflow: ellipsis;
                        font-family: "Hiragino Sans GB";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 20px;
                    }
                }
            }

            &-radio {
                display: flex;
                align-items: center;
                margin-left: 36px;
                cursor: pointer;

                color: #000;
                font-family: "Microsoft YaHei";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                gap: 4px;
            }
        }
    }

    .bottom-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        margin-right: 8px;
        margin-bottom: 16px;

        .btn {
            padding: 4px 6px;
            border-radius: 2px;
            border: 1px solid #CDCDCD;
            background: #FFF;

            color: @fill-black;
            font-family: "Microsoft YaHei UI";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;


        }

        .save {
            background-color: @primary-6;
            color: @fill-white;
            border: 1px solid @primary-6;
            margin-left: auto;

            &:hover {
                background-color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                background-color: @primary-4;
                border: 1px solid @primary-4;
            }
        }

        .cancel {
            &:hover {
                color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                color: @primary-6;
                border: 1px solid @primary-6;
            }
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;