.project {
    // background-color: red;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F5F5F5;

    .head {
        width: 100%;
        height: 46px;
        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: #fff;

        .trademark-icon {
            margin-left: 16px;
        }

        .show-icon {
            margin-left: 4px;
            cursor: pointer;
        }

        .return-icon {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .list-title {
        width: 100%;
        background: #11788F;
        height: 31px;
        display: flex;
        align-items: center;
        padding-left: 20px;

        color: #FFF;
        font-family: "Microsoft YaHei";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;


    }

    .project-list {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 43px 245px;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .list-main {
            width: 100%;
            height: 100%;
            background-color: #fff;
            padding: 21px 16px 12px 16px;
            overflow: hidden;

            .search {
                height: 26px;
                width: 180px;
            }

            .tn-project-table {
                margin-top: 11px;
                overflow: auto;
            }
        }

    }

}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;