.left-component-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 258px;
    height: 100%;
    flex-shrink: 0;
    background-color: @fill-1;

    .side-title {
        color: @fill-black;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 32px;
        padding: 10px 0 6px 0;
        margin: 0 8px 10px 8px;
        border-bottom: 1px solid @border-3;
    }

    .component-detail-row {
        display: flex;
        flex-direction: row;
        padding: 0 20px;
        margin-bottom: 10px;
        width: 100%;
        height: 66px;

        .left-block {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 66px;
            height: 66px;
            margin-right: 8px;
            background-color: red;
            flex-shrink: 0;
        }

        .right-block {
            display: flex;
            flex-direction: column;
            width: 134px;
            height: 66px;

            .c-top {
                height: 20px;
                margin-bottom: 2px;

                .ant-input {
                    width: 132px;
                    height: 20px;
                    padding: 2px 6px;
                    align-items: center;
                    border-radius: 2px;
                    border: 1px solid @border-2;
                    background: @fill-1;
                    color: @text-6;
                    font-family: "Hiragino Sans GB";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                }
            }

            .c-bottom {
                width: 132px;
                height: 38px;
                background-color: palevioletred;

                .ant-input {
                    width: 132px;
                    height: 43px;
                    padding: 2px 6px;
                    align-items: center;
                    border-radius: 0px 0px 2px 2px;
                    border: 1px solid @border-1;
                    background: @fill-1;
                    color: @text-6;
                    font-family: "Hiragino Sans GB";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                }
            }
        }
    }

    .side-title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: @fill-black;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        // height: 24px;
        // padding: 10px 0 6px 0;
        margin: 0 8px 10px 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid @border-3;
        gap: 10px;

        svg {
            cursor: pointer;
        }


        .ant-select {
            height: 24px;
            width: 127px;

            .ant-select-selector {
                height: 24px;
                padding: 2px 6px;
            }

            .ant-select-selection-item {
                overflow: hidden;
                color: @text-6;
                text-overflow: ellipsis;
                font-family: "Hiragino Sans GB";
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 20px;
            }
        }

        .upload-button {
            cursor: pointer;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            border-radius: 2px;
            border: 1px solid @border-1;
            background: @fill-1;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: @fill-2;
            }

            &:active {
                background: @fill-4;
            }
        }



    }

    .c-list {
        display: flex;
        flex-direction: column;
        width: 240px;
        height: 256px;
        overflow: auto;
        padding: 6px 0 6px 10px;
        margin-left: 8px;
        border: 1px solid @border-4;
        background: #FFF;
        gap: 4px;

        .c-list-row {
            display: flex;
            flex-direction: row;
            height: 58px;

            .c-row-left {
                width: 64px;
                height: 58px;
                margin-right: 4px;
            }

            .c-row-right {
                display: flex;
                flex-direction: column;
                padding: 2px 0;
                cursor: pointer;

                &-top {
                    width: 132px;
                    color: @text-6;
                    font-family: "Hiragino Sans GB";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &-middle {
                    width: 132px;
                    color: @text-6;
                    font-family: "Hiragino Sans GB";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    span {
                        margin-right: 4px;
                    }
                }

                &-bottom {
                    width: 132px;
                    color: @text-2;
                    font-family: "Hiragino Sans GB";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        // 滚动条
        &::-webkit-scrollbar {
            width: 17px;
            height: 54px;
            background-color: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            // -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
            opacity: 1;
            background-color: #d9d9d9;
            border-radius: 0;
            transition: background-color .3s;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: @primary-2;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: @primary-4;
        }
    }

    .create-btn {
        width: 60px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 2px;
        border: 1px solid #CDCDCD;
        background: #FFF;
        color: #000;
        font-family: "Microsoft YaHei UI";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        cursor: pointer;
        margin: 14px 0 0 24px;
        z-index: 2;

        &:hover {
            color: @primary-5;
            border: 1px solid @primary-5;
        }

        &:active {
            color: @primary-6 !important;
            border: 1px solid @primary-6 !important;
        }

        &-active {
            color: @primary-6 !important;
            border: 1px solid @primary-6 !important;
        }
    }
}

.side-title-pop {

    .ant-select-item {
        height: 24px;
        padding: 0 6px !important;
        min-height: 24px !important;
    }

    .ant-select-item-option-content {
        line-height: 24px !important;

        overflow: hidden;
        color: @text-6;
        text-overflow: ellipsis;
        font-family: "Hiragino Sans GB";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

.component-type-menu {
    display: flex;
    flex-direction: column;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid @fill-5;
    background: #FFF;

    .btn {
        overflow: hidden;
        color: @text-6;
        text-overflow: ellipsis;
        font-family: "Hiragino Sans GB";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        cursor: pointer;
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;