.operation-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 30px !important;
    background: #ECF5F8;
    padding-left: 5px;
    gap: 8px;

    svg {
        cursor: pointer;
    }

    .layer-select-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #FFF;
        height: 22px;
        width: 152px;

        .layer-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 4px;
        }

        .ant-select-selector {
            border: none !important;
            padding: 0 4px !important;
            height: 22px !important;
            margin-top: 1px !important;
            background-color: #FFF !important;
        }
    }

    .ucs-select-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #FFF;
        height: 22px;
        width: 152px;

        .ucs-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 4px;
        }

        .ant-select-selector {
            border: none !important;
            padding: 0 4px !important;
            height: 22px !important;
            margin-top: 1px !important;
            background-color: #FFF !important;
        }
    }

    .storey-select-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #FFF;
        height: 22px;
        width: 152px;

        .storey-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 4px;
        }

        .ant-select-selector {
            border: none !important;
            padding: 0 4px !important;
            height: 22px !important;
            margin-top: 1px !important;
            background-color: #FFF !important;
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;