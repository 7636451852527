
.color-palette-container {
    // height: 200px;
    position: relative;
    display: flex;
    // background-color: #eeeeee;
    width: 600px;

    .left-panel, .right-panel {
        width: 50%;
        padding: 10px;
    }
    .right-panel {
        display: flex;
        flex-direction: column;

    }

    .palette-saturation {
        position: relative;
        height: 200px;
    }
    .palette-hue {
        position: relative;
        margin-top: 6px;
        height: 18px;
    }
    .hue-pointer {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transform: translate(-9px, 0px);
        background-color: rgb(248, 248, 248);
        box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;
    }
    .input-wrapper {
        display: flex;
        padding-top: 4px;
        .input-item-hex {
            width: 80px;
        }
        .input-item-rgb {
            width: 50px;
            margin-left: 10px;
        }
    }


    .color-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        // width: 180px;
        height: 80px;
        z-index: 1001;
        .color-item {
            flex: 0, 0, 20px;
            width: 20px;
            height: 20px;
            padding: 2px 2px;
            cursor: pointer;
            &:hover {
                padding: 1px;
                .color-box {
                    width: 19px;
                    height: 19px;
                }
            }
        }
        .color-box {
            border: 1px solid #cccccc;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            // box-shadow: rgb(221 221 221) 0px 0px 0px 1px inset;
        }
    }

    .color-footer {
        // margin-top: 40px;
        display: flex;
        align-items: flex-end;
    }

    .current-color {
        width: 80px;
        height: 80px;
        border: 1px solid #666666;
        // margin-bottom: 78px;
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;