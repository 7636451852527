.elevator-car-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 258px;
    height: 100%;
    flex-shrink: 0;
    background-color: @fill-1;

    .side-title {
        color: @fill-black;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 32px;
        padding: 10px 0 6px 0;
        margin: 0 8px 10px 8px;
        border-bottom: 1px solid @border-3;
    }

    .enter-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 20px 6px 20px;
        // width: 100%;
        height: 24px;

        .tip {
            display: flex;
            align-items: center;
            width: 76px;
            height: 16px;
            line-height: 16px;
            color: @text-1;
            font-family: "Microsoft YaHei";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 132.497%;
        }

        .ant-input-affix-wrapper {
            width: 142px;
            height: 24px;
            border-radius: 2px;
            border: 1px solid @border-2;
            background: @primary-2;

            color: @text-3;
            font-family: "Hiragino Sans GB";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            transition: all 0s;
            padding: 0 6px;

            &-focused {
                // background-color: @fill-5 !important;
                border: 1px solid @primary-6 !important;
                color: @fill-black !important;
                box-shadow: none !important;
            }

            &:hover {
                border: 1px solid @border-3;
                color: @text-4;
            }

            &:focus {
                box-shadow: none;
            }

            .ant-input-suffix {
                color: @fill-black;
            }
        }


    }

    .preview-area {
        display: flex;
        width: 100%;
        height: 84px;
        align-items: center;
        justify-content: center;
        margin-top: 4px;
    }

    .btn-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        margin-right: 20px;
        margin-top: 14px;

        .btn {
            padding: 4px 6px;
            border-radius: 2px;
            border: 1px solid #CDCDCD;
            background: #FFF;

            color: @fill-black;
            font-family: "Microsoft YaHei UI";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;


        }

        .save {
            background-color: @primary-6;
            color: @fill-white;
            border: 1px solid @primary-6;
            margin-left: auto;

            &:hover {
                background-color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                background-color: @primary-4;
                border: 1px solid @primary-4;
            }
        }

        .cancel {
            &:hover {
                color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                color: @primary-6;
                border: 1px solid @primary-6;
            }
        }
    }

    .physical-area {
        display: flex;
        flex-direction: column;
        margin-top: auto;

        .divider {
            width: 242px;
            height: 1px;
            background-color: @border-3;
            margin: 0 8px;
        }

        .physical-info {
            display: flex;
            flex-direction: column;
            padding: 9px 8px 10px 8px;
            width: 100%;
            height: 355px;

            .title {
                width: 242px;
                padding: 2px 0;
                height: 21px;
                border-radius: 2px;
                background: @primary-1;
                color: @text-6;
                font-family: "Microsoft YaHei";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-left: 13px;
                margin-bottom: 6px;
            }

            .msg-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 20px 6px 12px;
                height: 24px;

                .tip {
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    width: 76px;
                    height: 16px;
                    line-height: 16px;
                    color: @text-1;
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 132.497%;
                }

                .detail {
                    // display: flex;
                    // justify-content: end;
                    flex: 1 1 auto;
                    color: @text-6;
                    font-family: "Hiragino Sans GB";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    text-align: end;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;