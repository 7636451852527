.room-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    background-color: @fill-1;

    .room-side-title {
        flex-shrink: 0;
        color: @fill-black;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 32px;
        padding: 10px 0 6px 0;
        margin: 0 8px 10px 8px;
        border-bottom: 1px solid @border-3;
    }

    .room-content-area {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-bottom: 10px;

        .room-content {
            display: flex;
            flex-direction: column;
            overflow: auto;

            .room-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 20px 4px 20px;
                padding: 0 4px;
                height: 24px;
                border-radius: 2px;
                cursor: pointer;
                &:hover {
                    background: @primary-4;
                }


                .text-area {
                    display: flex;
                    flex: 1 1 auto;
                    color: @text-6;
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }

                .icon-area {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    margin-left: 1px;
                    cursor: pointer;
                }

                &-active {
                    background: @primary-2 !important;
                    // background: @primary-7 !important;
                }

                // &:hover {
                // background-color: @primary-2;
                // }
            }

            .room-sub-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 20px 4px 32px;
                background-color: @fill-1;
                padding: 0 4px;
                border-radius: 2px;
                height: 24px;
                cursor: pointer;

                .text-area {
                    display: flex;
                    flex: 1 1 auto;
                    color: @text-6;
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;

                    .ant-input {
                        height: 20px;
                        border-radius: 2px;
                        border: 1px solid #E7E7E7;
                        background: #FFF;
                        color: @text-6;
                        font-family: "Microsoft YaHei";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        padding: 0 4px;
                        box-shadow: none;
                    }
                }

                .icon-area {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    margin-left: 1px;
                    cursor: pointer;
                    color: #FFF;
                }

                .icon-list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .icon-area {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-shrink: 0;
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                        margin-left: 1px;
                        cursor: pointer;
                        color: #FFF;
                    }
                }

                &-active {
                    background: @primary-2 !important;
                    // background: @primary-7 !important;
                }

                // &:hover {
                // background-color: @primary-2;
                // }
            }

            .room-btn-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                height: 24px;
                margin-right: 20px;
                // margin-bottom: 10px;
                margin-bottom: auto;

                .btn {
                    padding: 4px 6px;
                    border-radius: 2px;
                    border: 1px solid #CDCDCD;
                    background: #FFF;

                    color: @fill-black;
                    font-family: "Microsoft YaHei UI";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    cursor: pointer;
                }

                .save {
                    background-color: @primary-6;
                    color: @fill-white;
                    border: 1px solid @primary-6;
                    margin-left: auto;

                    &:hover {
                        background-color: @primary-5;
                        border: 1px solid @primary-5;
                    }

                    &:active {
                        background-color: @primary-4;
                        border: 1px solid @primary-4;
                    }
                }

                .cancel {
                    &:hover {
                        color: @primary-5;
                        border: 1px solid @primary-5;
                    }

                    &:active {
                        color: @primary-6;
                        border: 1px solid @primary-6;
                    }
                }
            }
        }
    }

    .room-btn-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        height: 24px;
        margin-right: 20px;
        margin-bottom: 10px;

        .btn {
            padding: 4px 6px;
            border-radius: 2px;
            border: 1px solid #CDCDCD;
            background: #FFF;

            color: @fill-black;
            font-family: "Microsoft YaHei UI";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;
        }

        .save {
            background-color: @primary-6;
            color: @fill-white;
            border: 1px solid @primary-6;
            margin-left: auto;

            &:hover {
                background-color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                background-color: @primary-4;
                border: 1px solid @primary-4;
            }
        }

        .cancel {
            &:hover {
                color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                color: @primary-6;
                border: 1px solid @primary-6;
            }
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;