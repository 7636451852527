.tn-modal-panel {

    .tn-modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 16px;

        .ant-btn.modal-footer-button {
            width: 64px;
            height: 24px;
            border-radius: 4px;
            padding: 0;
            border: 0;
            // margin-top: 16px;
            color: #FFF;

            &.primary {
                background-color: #0088CF !important;
                font-weight: 600 !important;
            }

            &.danger {
                background-color: #CB272D !important;
                font-weight: 600 !important;
            }

            &.second {
                background-color: #AFAFAF !important;
                font-weight: 600 !important;
            }
        }
    }
}

.delete-modal-panel {
    .ant-modal .ant-modal-content {
        width: 312px;
        top: 35%;
        left: 0;
        background: #FFF;
        // padding: 28px 24px 16px 24px;
        padding: 16px;
    }

    .ant-modal-confirm-body {

        .ant-modal-confirm-paragraph {
            width: 100%;
        }

        .ant-modal-confirm-title {
            color: #CB272D;
            font-family: Hiragino Sans GB;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .ant-modal-confirm-content {
            color: #000;
            font-family: Hiragino Sans GB;
            font-size: 14px;
            font-style: normal;
            // // font-weight: 300;;
            line-height: normal;
        }
    }

    &.closable-hidden {
        .ant-modal-close {
            display: none;
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;