.tn-select-modal {
    // width: 424px;
    padding: 0px;

    .ant-modal-content {
        padding: 0px;


    }

    .body {
        height: 330px;

        .title {
            display: flex;
            padding: 4px 4px 4px 8px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            background: @primary-6;

            color: #FFF;
            font-family: "Microsoft YaHei UI";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            .icon {
                cursor: pointer;
            }
        }

        .list {
            margin-top: 14px;
            padding: 0px 16px;
            display: flex;
            height: 240px;
            flex-direction: column;
            overflow: auto;

            .list-item {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                height: 20px;
                flex-shrink: 0;
                align-items: center;
                cursor: pointer;

                &:hover{
                    background-color: aliceblue;
                }

                .value {
                    color: var(--text-6, #000);
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 132.497%;
                }

                .icon {
                    margin-left: auto;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 1px solid @primary-6;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .select{
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: @primary-6;
                    }
                }
            }
        }

        .bottom-panel {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            margin-right: 22px;
            // margin-bottom: 22px;
    
            .btn {
                padding: 4px 6px;
                border-radius: 2px;
                border: 1px solid #CDCDCD;
                background: #FFF;
    
                color: @fill-black;
                font-family: "Microsoft YaHei UI";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                cursor: pointer;
    
    
            }
    
            .save {
                background-color: @primary-6;
                color: @fill-white;
                border: 1px solid @primary-6;
                margin-left: auto;
    
                &:hover {
                    background-color: @primary-5;
                    border: 1px solid @primary-5;
                }
    
                &:active {
                    background-color: @primary-4;
                    border: 1px solid @primary-4;
                }
            }
    
            .cancel {
                &:hover {
                    color: @primary-5;
                    border: 1px solid @primary-5;
                }
    
                &:active {
                    color: @primary-6;
                    border: 1px solid @primary-6;
                }
            }
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;