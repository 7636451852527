.texture-select-container {
    display: flex;
    flex: 1 1 auto;

    // .select-box{
    //     flex: 1 1 auto;
    // }
    .upload-button-warp {
        cursor: pointer;


        display: flex;
        width: 22px;
        height: 20px;
        margin-left: 6px;
        padding-left: 4px;
        margin-top: 2px;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 2px;
        border: 1px solid @border-1;
        background: @fill-1;

        &:hover {
            background: @fill-2;
        }

        &:active {
            background: @fill-4;
        }
    }

    .ant-select {
        .ant-select-selector {
            .ant-select-selection-item {
                color: var(--text-6, #000);
                font-family: "Hiragino Sans GB";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;

                top: -2px
                    /* 166.667% */
            }
        }
    }

}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;