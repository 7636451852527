.axis-net-list {
    display: flex;
    flex-direction: column;
    // flex: 1 1 auto;
    width: 139px;
    // height: 237px;
    height: 261px;
    // background-color: green;
    border-top: 1px solid @border-5;
    // border-left: 1px solid @border-5;
    // border-right: 1px solid @border-5;


    .head-row {
        display: flex;
        flex-direction: row;


        .head-cell {
            display: flex;
            // border: 1px solid @border-5;
            // border-left: 1px solid @border-5;
            // border-top: 1px solid @border-5;
            // border-bottom: 1px solid @border-5;
            padding: 0 4px;
            color: @fill-black;
            font-family: "Microsoft YaHei";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .spacing {
            width: 77px;
        }

        .spacing-scroll {
            width: 73px;
        }

        .count {
            width: 62px;
        }

        .count-scroll {
            width: 66px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: hidden;
        border-left: 1px solid @border-5;
        border-right: 1px solid @border-5;
        border-bottom: 1px solid @border-5;

        .content-body {
            display: flex;
            flex-direction: column;
            // flex: 1 1 auto;
            overflow: auto;

            // border: 1px solid @border-5;
            // border-bottom: 1px solid @border-5;
            // background-color: rebeccapurple;

            .body-row {
                display: flex;
                flex-direction: row;
                height: 18px;

                .body-cell {
                    display: flex;
                    align-items: center;
                    // border: 1px solid @border-5;
                    // border-top: 1px solid @border-5;
                    // border-bottom: 1px solid @border-5;

                    // &:not(:first-child) {
                    //     border-bottom: 1px solid @border-5;
                    // }

                    // &:not(:last-child) {
                    //     border-top: 1px solid @border-5;
                    // }

                    color: @fill-black;
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    svg {
                        width: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .ant-input {
                        height: 16px;
                        border: none;
                        padding: 0 4px;

                        color: @fill-black;
                        font-family: "Microsoft YaHei";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .spacing {
                    width: 61px;
                }

                .count {
                    width: 62px;
                }

                .arrow {
                    width: 16px;
                    flex-shrink: 0;
                }

            }

            .gray-block {
                flex: auto;
                width: 16px;
                background-color: #f0f0f0;
            }
        }

        .extra {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 4px;
            margin-top: auto;
            margin-bottom: 4px;

            .text {
                color: @text-1;
                font-family: "Microsoft YaHei";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 132.497%;
            }

            .ant-input {
                width: 55px;
                height: 24px;
                margin-left: auto;
                color: @text-3;
                font-family: "Hiragino Sans GB";
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 20px;
                box-shadow: none;
                border-radius: 2px;
                border: 1px solid @primary-2;
                background: @primary-2;
                border-radius: 2px;
                border: 1px solid @border-2;

                &:hover {
                    border: 1px solid @primary-3;
                }

                &:focus {
                    border: 1px solid @fill-5;
                    background-color: @fill-5;
                    color: @fill-black;
                }
            }
        }
    }



    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-left: auto;
        cursor: pointer;

        &:hover {
            background-color: @primary-4;
        }

        &:active {
            background-color: @primary-6;
        }
    }

    .left-border {
        border-left: 1px solid @border-5;
    }

    .right-border {
        border-right: 1px solid @border-5;
    }

    .top-border {
        border-top: 1px solid @border-5;
    }

    .bottom-border {
        border-bottom: 1px solid @border-5;
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;