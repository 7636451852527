.side-bar-main .body {
    .top {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        padding: 0px 12px;

        .show-image {
            width: 66px;
            height: 66px;
        }

        .introduce {
            margin-left: 6px;
            display: flex;
            padding: 2px 6px;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
            border-radius: 2px;
            border: 1px solid @border-2;
            background: @fill-1;
            height: 24px;

            color: @text-4;
            font-family: "Hiragino Sans GB";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            /* 166.667% */
            display: flex;
            align-items: center;

            white-space: nowrap;
            /* 禁止文本换行 */
            overflow: hidden;
            /* 隐藏溢出内容 */
            text-overflow: ellipsis;
            /* 溢出部分使用省略号代替 */
        }
    }

    .buttom-title {
        color: @fill-black;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 132.497%;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // width: 242px;
        padding: 0px 8px 6px 0px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid @border-3;
        height: 26px;

        margin-top: 10px;

        .house-svg {
            height: 24px;
            width: 24px;
        }

        .select {
            height: 24px;
            width: 120px;

            .ant-select-selector {
                align-items: center;

                .ant-select-selection-item {
                    height: 24px;
                    margin-bottom: -6px;

                    color: @text-6;
                    font-family: "Hiragino Sans GB";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    /* 166.667% */
                }

                .ant-select-selection-search {
                    height: 24px;
                }

                width: 127px;
                height: 24px;
                padding: 6px;

            }


        }

        .upload-btn {
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 2px;
            border: 1px solid @fill-3;
            background: @fill-1;

            width: 22px;
            height: 22px;
            flex-shrink: 0;

            cursor: pointer;

            &:hover {
                background-color: @fill-2;
            }
        }
    }

    .image-list {
        display: flex;
        flex-direction: column;
        height: 132px;
        overflow: auto;
        // background-color: red;
        border: 1px solid @border-4;

        padding: 5px 9px;

        &::-webkit-scrollbar {
            width: 17px;
            height: 8px;
            background-color: #F0F0F0;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }

        .row {
            // background-color: blue;
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;

            .row-item {
                width: 66px;
                height: 60px;
                border: 1px solid @border-3;
                margin-right: 4px;

                cursor: pointer;

                &:hover {
                    border: 1px solid @primary-6;
                }

                &-active {
                    border: 1px solid @primary-6;
                }
            }
        }
    }
}

.texture-delete-menu {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    overflow: hidden;

    border-bottom: 1px solid var(--fill-5, #DAE3E5);
    background: var(--bg-2, #FFF);

    color: var(--text-6, #000);
    text-overflow: ellipsis;
    font-family: "Hiragino Sans GB";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    /* 183.333% */

    cursor: pointer;
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;