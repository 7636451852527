.side-bar-main .body .detail {
    .list-title {
        background-color: @fill-white;
        display: flex;
        flex-direction: row;
        height: 25px;
        justify-content: center;
        align-items: center;

        color: @text-4;
        font-family: "Microsoft YaHei";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding: 4px;

        .road-name {
            height: 17px;
            width: 50%;
            display: flex;
            justify-content: center;
            padding-right: 4px;
        }

        .road-type {
            height: 17px;
            width: 50%;
            display: flex;

            justify-content: center;
            align-items: center;

            .type-icon {
                width: 16px;
                height: 16px;
                background: @primary-4;
                display: flex;
                margin-left: 2px;
                cursor: pointer;
            }
        }


    }

    .road-list {
        margin-top: 6px;
        display: flex;
        flex-direction: column;

        // align-items: center;
        justify-content: center;

        .road-item {
            display: flex;
            height: 24px;
            align-items: center;
            border-radius: 2px;

            margin-bottom: 2px;

            cursor: pointer;

            &:hover {
                background-color: @primary-2;
            }

            &:active {
                background-color: @primary-7;
            }

            &-active {
                background-color: @primary-7;
            }

            .name {
                width: 50%;

                overflow: hidden;
                color: #000;

                text-overflow: ellipsis;
                font-family: "Microsoft YaHei";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 132.497%;
                /* 15.9px */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;

                padding-left: 6px;

            }

            .select {
                width: 50%;
                height: 24px;
                display: flex;
                flex-shrink: 0;

                .ant-select-selector {
                    height: 24px;
                    padding: 0 11px;
                    width: 100%;
                }
            }
        }
    }
}

.road-type-model {
    .ant-modal-content {
        padding: 0px;
        height: 539px;
        width: 596px;
    }

    .model-main {
        height: 539px;
        width: 596px;
        padding-bottom: 16px;
        display: flex;

        flex-direction: column;

        .title {
            height: 32px;
            padding: 4px 4px 4px 8px;
            display: flex;
            flex-direction: row;
            align-items: center;

            background-color: @primary-6;

            .name {
                color: #FFF;
                font-family: "Microsoft YaHei UI";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .icon {
                margin-left: auto;
                cursor: pointer;
            }
        }

        .type-list {
            display: flex;
            flex-direction: column;
            // align-items: center;
            gap: 10px;
            margin: 16px;
            border-radius: 2px;
            border: 1px solid @fill-3;

            height: 168px;

            .list-title {
                display: flex;
                height: 21px;
                flex-direction: row;
                align-items: center;
                padding-left: 13px;
                background-color: @primary-1;
                border-radius: 2px;

                .icon {
                    margin-left: auto;
                    margin-right: 13px;
                    width: 16px;
                    height: 16px;

                    cursor: pointer;
                }
            }

            .list-table {
                display: flex;
                flex-direction: column;
                overflow: auto;
                margin-left: 23px;
                margin-right: 23px;
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;


                .table-title {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    margin-bottom: 4px;

                    .title-item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // width: 132px;
                        width: 25%;

                        color: @text-4;
                        font-family: "Microsoft YaHei";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .table-body {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    overflow: auto;

                    .table-item {
                        margin-top: 2px;

                        color: @text-2;
                        font-family: "Microsoft YaHei";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        display: flex;
                        flex-direction: row;
                        height: 26px;
                        flex-shrink: 0;
                        border-radius: 1px;

                        cursor: pointer;

                        &:hover {
                            color: @text-4;
                            background: @primary-2;
                        }

                        &-active {
                            color: @text-4;
                            background: @primary-7;
                        }

                        .item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 25%;
                        }
                    }
                }


            }
        }

        .type-detail {
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: 2px;
            border: 1px solid @fill-3;
            background: @fill-1;

            margin: 0px 16px 0px 16px;

            flex: 1 1 auto;

            .title {
                display: flex;
                height: 17px;
                padding: 13px;
                justify-content: space-between;
                align-items: center;
                border-radius: 2px;
                background: @primary-1;
            }

            .top {
                margin-left: 24px;
                height: 24px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .name {
                    width: 60px;
                    flex-shrink: 0;
                    color: @text-1;
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 132.497%;
                }

                .menu {
                    width: 82px;
                    display: flex;
                    flex-direction: row;
                    border-radius: 2px;
                    background: @primary-1;
                    padding: 2px;

                    .item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;

                        color: @text-3;
                        font-family: "Hiragino Sans GB";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 20px;

                        cursor: pointer;

                        &-active {
                            color: @primary-6;
                            font-family: "Hiragino Sans GB";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;

                            background-color: #FFF;
                        }
                    }
                }

                .label {
                    margin-left: 42px;
                    color: @text-1;
                    font-family: "Microsoft YaHei";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 132.497%;
                    width: 60px;
                }

                .input {
                    width: 91px;
                    height: 24px;
                    padding: 1px 4px 1px 6px;
                    border-radius: 2px;
                    border: 1px solid @border-2;
                    background: @primary-2;

                    color: @text-3;
                    font-family: "Hiragino Sans GB";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                }



            }

            .detail {
                .detail-body {
                    // margin: 0px 24px 0px 24px;
                    margin-left: 24px;
                    height: 100%;
                    display: flex;

                    flex-direction: row;

                    .detail-main {
                        width: 260px;
                        background: @fill-3;
                        display: flex;
                        flex-direction: column;
                        flex-shrink: 0;

                        &-right {
                            margin-left: 8px;
                        }

                        .title {
                            color: @text-6;
                            font-family: "Microsoft YaHei";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;

                            height: 20px;
                            padding: 2px 4px;

                            margin-bottom: 2px;

                            .icon {
                                width: 16px;
                                height: 16px;
                                cursor: pointer;
                            }
                        }

                        .list-item {
                            display: flex;
                            flex-direction: row;
                            width: 256px;
                            padding: 2px 4px;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 2px;
                            flex-shrink: 0;

                            .index {
                                color: @text-1;
                                font-family: "Microsoft YaHei";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 132.497%;
                            }

                            .item-input {
                                height: 20px;
                                padding: 2px 4px;
                                width: 209px;
                            }

                            .delete-icon {
                                width: 16px;
                                height: 16px;
                                cursor: pointer;
                            }
                        }


                    }

                    .right-detail {

                        width: 260px;
                        background: @fill-3;
                    }
                }

                .detail-bottom {
                    display: flex;
                    flex-direction: row;
                    justify-content: end;
                    align-items: center;
                    height: 36px;
                    width: 100%;
                    padding: 6px;


                    .save-btn {
                        display: flex;
                        padding: 4px 6px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 2px;
                        background: @primary-6;

                        color: #FFF;
                        font-family: "Microsoft YaHei UI";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        /* 133.333% */

                        cursor: pointer;
                    }

                    .cancel-btn {
                        margin-left: 6px;
                        display: flex;
                        padding: 4px 6px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 2px;
                        border: 1px solid #CDCDCD;
                        background: #FFF;

                        color: #000;
                        font-family: "Microsoft YaHei UI";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        /* 133.333% */
                        cursor: pointer;
                    }
                }
            }


        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;