.storey-panel {
    .body {
        .head {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 2px;
            background: @primary-1;
            height: 21px;
            padding: 0px 4px 2.5px 13px;
            margin-top: 10px;
            flex-shrink: 0;

            color: @text-6;
            font-family: "Microsoft YaHei";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .detail {
            .selected {
                background: @primary-6;
                color: #DCDCDC;
            }

            .storey-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 2px;
                margin-bottom: 2px;
                height: 20px;

                padding-left: 8px;
                align-items: center;

                &:hover {
                    background: @primary-4;
                }




                .right-svg {
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    margin-bottom: 3px;

                    .svg {
                        margin-left: 3px;
                    }
                }
            }


            .bottom-panel {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                margin-top: 10px;

                .btn {
                    padding: 4px 6px;
                    border-radius: 2px;
                    border: 1px solid #CDCDCD;
                    background: #FFF;

                    color: @fill-black;
                    font-family: "Microsoft YaHei UI";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    cursor: pointer;


                }

                .save {
                    background-color: @primary-6;
                    color: @fill-white;
                    border: 1px solid @primary-6;
                    margin-left: auto;

                    &:hover {
                        background-color: @primary-5;
                        border: 1px solid @primary-5;
                    }

                    &:active {
                        background-color: @primary-4;
                        border: 1px solid @primary-4;
                    }
                }

                .cancel {
                    &:hover {
                        color: @primary-5;
                        border: 1px solid @primary-5;
                    }

                    &:active {
                        color: @primary-6;
                        border: 1px solid @primary-6;
                    }
                }
            }
        }
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;