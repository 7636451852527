.side-bar-main .body .column-body-detail {
    height: 100%;
    display: flex;

    flex-direction: column;

    padding: 10px 0px;

    .select-type {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 24px;

        .type-name {
            color: var(--text-1, #818181);
            font-family: "Microsoft YaHei";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 132.497%;
            // height: 16px;
            display: flex;
            align-items: center;

            width: 76px;
            margin-left: 12px;
            /* 15.9px */
        }

        .type-select {
            flex: 1 1 auto;
            margin-right: 12px;
        }
    }

    .panel-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 21px;
        flex-shrink: 0;
        padding: 2px 13px;
        align-items: flex-start;
        border-radius: 2px;
        background: @primary-1;

        color: var(--text-6, #000);
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-top: 6px;
    }

    .panel-main {
        display: flex;
        flex-direction: row;
        margin-top: 4px;

        .show-scene {
            display: flex;
            width: 88px;
            height: 88px;
            background-color: #818181;
            margin-right: 3px;

        }

        .edit-table {
            display: flex;
            flex-direction: column;

            .table-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 5px;

                color: @text-4;
                font-family: "Microsoft YaHei";
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                .size {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 81px;
                    height: 17px;
                    margin-right: 2px;
                    background-color: #fff;
                }

                .angle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 66px;
                    height: 17px;
                    background-color: #fff;
                    margin-right: 2px;
                }

            }

            .table-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 149px;

                margin-top: 4px;

                color: @text-1;
                font-family: "Microsoft YaHei";
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 132.497%;

                .input-1 {
                    display: flex;
                    flex-shrink: 0;
                    width: 51px;
                    height: 18px;
                    padding: 0px;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;

                    color: @text-3;
                    font-family: "Hiragino Sans GB";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    /* 200% */

                    border-radius: 2px;
                    background: @primary-2;


                }

                .name-2 {
                    margin-left: auto;
                }

                .input-2 {
                    display: flex;
                    width: 36px;
                    height: 18px;
                    padding: 1px 4px;
                    align-items: center;
                    gap: 4px;
                    border-radius: 2px;
                    background: @primary-2;

                    color: @text-3;
                    font-family: "Hiragino Sans GB";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                }
            }
        }
    }

    .btn-list {
        display: flex;
        width: 100%;
        padding: 4px 12px 4px 4px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 4px;
        margin-top: 10px;

        .save {
            display: flex;
            margin-left: auto;
            padding: 4px 6px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            border-radius: 2px;
            border: 1px solid #CDCDCD;
            background: #FFF;

            color: #FFF;
            font-family: "Microsoft YaHei UI";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;

            background-color: @primary-6;
            color: @fill-white;
            border: 1px solid @primary-6;

            &:hover {
                background-color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                background-color: @primary-4;
                border: 1px solid @primary-4;
            }
        }

        .cancel {
            padding: 4px 6px;
            border-radius: 2px;
            border: 1px solid #CDCDCD;
            background: #FFF;

            color: @fill-black;
            font-family: "Microsoft YaHei UI";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;

            &:hover {
                color: @primary-5;
                border: 1px solid @primary-5;
            }

            &:active {
                color: @primary-6;
                border: 1px solid @primary-6;
            }
        }
    }
}

.column-input-default-option {

    background-color: #fff;

    .option-item {
        color: @text-1;
        font-family: "Hiragino Sans GB";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;

        height: 20px;
        display: flex;
        align-items: center;
        padding-left: 4px;

        &:hover {
            background-color: @primary-2;
        }

        cursor: pointer;
    }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;