// 全局样式

.tn-base {
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.upload-download-panel {
    position: fixed;
    z-index: 1003 !important;
}

.fill-remaining-space {
    flex: 1 1 auto;
}

// 弹窗叉号
.tn-base-modal .ant-modal-content .ant-modal-close {

    .ant-modal-close-x {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

// 弹窗
.tn-base-modal {
    &.closable-hidden {
        .ant-modal-close {
            display: none;
        }
    }

    &.no-radius {
        .ant-modal-content {
            border-radius: 0;
        }
    }

    &.no-padding {
        .ant-modal-content {
            padding: 0;
        }
    }
}

.tn-base-modal .ant-modal-content {
    width: 100%;
    background: #FFF;
    display: inline-flex;
    // padding: 28px 24px 16px 24px;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    // gap: 16px;
    // border-radius: 8px;
    border-radius: 0;

    .ant-modal-footer {
        // margin-top: 16px;
        // height: 24px;
        height: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .btn {
            width: 64px;
            height: 24px;
            flex-shrink: 0;
            border-radius: 4px;
            line-height: 24px;

            color: #FFF;
            font-family: Hiragino Sans GB;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            display: flex;
            align-items: center;

            >span {
                line-height: 1;
            }

            &.primary {
                background-color: @theme-color-fir !important;
            }

            &.danger {
                background-color: @red-base !important;
            }

            &.cancel {
                background-color: @grey-base !important;
            }
        }
    }
}

//边距修改
.tn-base-modal .ant-modal-content .ant-modal-body {
    width: 100%;
    height: 100%;
}


.ellipsis {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// tncet-common中该组件有padding，height差6
.tn-context-menu-wrapper {
    padding: 0 !important;
    transform: translateY(-4px);
}

// antd的Radio组件
.ant-radio {
    position: relative;
    top: 1px;

    .ant-radio-inner {
        border: 1px solid #333;
    }
}

.ant-radio-wrapper {
    .ant-radio-checked {
        .ant-radio-inner {
            border-color: #333;
            background-color: #FFF;

            &::after {
                background-color: #333;
            }
        }
    }

    span.ant-radio+* {
        padding-inline-start: 4px;
        padding-inline-end: 4px;

        color: #000;
        font-family: Hiragino Sans GB;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

// 主题色分为5个层级，从小到大代表颜色从浅到深
@theme-color-fir: #0088CF;
@theme-color-sec: #0069A0;
@theme-color-thi: #004D76;

@red-base: #FF4D4F;
@grey-base: #AFAFAF;

// 工具栏高度
@tool-height: 104px;

// 滚动条
&::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: #F0F0F0;
}

&::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
    opacity: .2;
    background-color: #C9CDD4;
    border-radius: 12px;
    transition: background-color .3s;
}

&::-webkit-scrollbar-thumb:hover {
    background-color: @primary-2;
}

&::-webkit-scrollbar-thumb:active {
    background-color: @primary-4;
}

// 下拉框样式
.ant-select-dropdown .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
    background-color: @fill-5 !important;
}

.ant-select-dropdown .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb:hover {
    background-color: @primary-3 !important;
}

.ant-select-dropdown .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb:active {
    background-color: @primary-6 !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 2px 6px;
    border-radius: 2px;
    border: 1px solid @border-2;
    background: @fill-1;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active {
    border-color: @primary-6 !important;
    /* 聚焦时的边框颜色 */
    box-shadow: none;
    /* 聚焦时的阴影 */
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: @border-3;
}

.ant-select-dropdown {
    padding: 0px !important;
}

.ant-select-dropdown .ant-select-item {
    padding: 6px 10px 6px 4px;
    border-bottom: 1px solid #E5E6EB;
    background: @fill-white;
    border-radius: 0px;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @fill-1;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @fill-3;
}

::selection {
    background: #11788f !important;
}

html {
    --antd-wave-shadow-color: #11788f !important;
}