.static-input-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 3px;
    position: relative;

    .static-tool-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: @primary-4;
        width: 54px;
        flex-shrink: 0;
        height: 100%;

        .cancel {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
        }

        .tool {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .ant-input-affix-wrapper-sm {
        padding: 0px 10px 0px 14px;
        height: 26px;
        border-radius: 0px;
        border: none;
    }

    .ant-input-affix-wrapper .ant-input-prefix {
        margin-inline-end: 9px
    }

    .static-prefix {
        display: flex;
        align-items: center;

        .tip-cmd {
            margin-right: 6px;
            font-weight: bold;
        }
    }

    .cmd-options {
        display: flex;
        align-items: center;
        white-space: pre;

        &.active::before {
            content: ' [ ';
        }

        &.active::after {
            content: ' ] ';
        }

        .cmd-options-item {
            cursor: pointer;
            padding: 0 2px;
            font-size: 12px;
            height: 20px;
            line-height: 20px;
            background-color: #d2d2d2;
            border-radius: 2px;

            &:hover {
                background-color: #b3b3b3;
            }

            &:not(:last-of-type) {
                margin-right: 4px;
            }

            .key {
                color: @primary-6;
            }
        }
    }

    .log-history-tag {
        background-color: #626262;
        // background: linear-gradient(to bottom, rgba(98,98,98,0), rgba(98,98,98,0.8));
        color: #fff;
        font-size: 12px;
        line-height: 12px;
        padding: 2px 2px;
        position: absolute;
        left: 10px;
        border-radius: 2px;
        transition: all .5s;
        z-index: -1;
        opacity: 0;
        letter-spacing: 1px;
        flex-shrink: 0;
        height: 20px;

        white-space: nowrap;

        &.visible {
            opacity: 1;
            z-index: 1000;
        }

        &-0 {
            top: -19px;
        }

        &-1 {
            top: -38px;
        }

        &-2 {
            top: -57px;
        }
    }

    .stmode-command-list {
        z-index: 1002;
        height: 100px;
        border: 1px solid #000;
        overflow: auto;
        background-color: #fff;
        min-width: 150px;
        display: none;

        position: absolute;
        top: -106px;

        &.visible {
            display: block;
        }

        .cmd-item {
            padding: 0 4px;
            user-select: none;
            cursor: pointer;

            // font-size: 13px;
            &.active {
                background-color: @primary-6;
                color: #fff;
            }

            &:hover:not(.active) {
                background-color: @fill-6;
            }
        }
    }
}

.log-history-menu {
    background-color: #c8c8c8;
    border: 1px solid #000;
    height: 150px;
    overflow-y: auto;
    padding: 4px 10px;
    font-size: 12px;
    white-space: pre;
    // .log-history-text {

    // }
}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;