.main-dropdown-box-item {
    color: var(--text-6, #000);
    font-family: "Microsoft YaHei";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.497%;
    cursor: pointer;
    margin-bottom: 2px;
    /* 15.9px */


    .main-dropdown-box-item-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between; /* 让子元素在两侧对齐 */

        border-radius: 2px;
        background: #ffffff;
        
        height: 24px;
        padding-left: 8px;
        
        align-items: center;
        .right-svg{
            width: 16px;
            height: 16px;
            margin-right: 4px;
            cursor: pointer;
            .svg{
                margin-top: 2px;
                margin-left: 2px;
            }
        }
    }

    .selected{
        background: var(--primary-2, #DCEBEE);
    }

    .main-dropdown-box-item-expand{
        margin-left: 12px;
        max-height: 300px;
        overflow-y: auto;
    }

}
@primary-color: #11788f;@fill-1: #f9fafa;@fill-2: #f3f3f3;@fill-3: #f5f5f5;@fill-4: #eeeeee;@fill-5: #dae3e5;@fill-6: #c9cdd4;@fill-white: #ffffff;@fill-black: #000000;@text-1: #818181;@text-2: #8a979a;@text-3: #86909c;@text-4: #4e5969;@text-5: #1d2129;@text-6: #000000;@primary-1: #d2e1e4;@primary-2: #dcebee;@primary-3: #b3d1d7;@primary-4: #78b1be;@primary-5: #479caf;@primary-6: #11788f;@primary-7: #c6dde1;@border-1: #e5e6eb;@border-2: #c4c4c4;@border-3: #a5a5a5;@border-4: #000000;@border-5: #7E7E7E;@border-6: #CDCDCD;